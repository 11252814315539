import React from 'react';

import './Section.scss';

const section3 = () => {
  return (
    <section className="section-3">
      <p>The agency service of your sea vessels by our company ensures high-quality timely and accurate execution of all agent functions.</p>
    </section>
  );
};

export default section3;