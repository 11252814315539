import React from 'react';

import './Footer.scss';
import img1 from '../../assets/images/icons/icon1-dark.png';
import img2 from '../../assets/images/icons/icon2-dark.png';
import img3 from '../../assets/images/icons/icon3-dark.png';
import img4 from '../../assets/images/icons/icon4-dark.png';

const footer = () => {
  return (
    <footer>
      <p className="address">
        <img src={img1} alt="icon" />
        Off. 33, 76, Bolshaya Arnaytskaya str., Odessa, 65045, Ukraine
      </p>
      <p className="tel1">
        <span>
          <img src={img2} alt="icon" />
        </span>
        <span>
          <a href="tel:+38(048)759-42-61">Tel.  :+38(048)759-42-61</a>
          <a href="fax:+38(048)239-02-65">Fax.  :+38(048)239-02-65</a>
        </span>
      </p>
      <p className="tel2">
        <img src={img3} alt="icon" />
        <a href="tel:+38(067)275-09-22">Mob.  :+38(067)275-09-22</a>
      </p>
      <p className="email">
        <img src={img4} alt="icon" />
        <a href="mailto:global@agencyservices.com.ua">E-mail:global@agencyservices.com.ua</a>
      </p>
    </footer>
  );
};

export default footer;