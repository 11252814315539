import React from 'react';

import './Section.scss';
import img1 from '../../assets/images/pic1.png';
import img2 from '../../assets/images/pic2.png';
import img3 from '../../assets/images/pic3.png';
import img4 from '../../assets/images/pic4.png';
import img5 from '../../assets/images/pic5.png';
import imgImg from '../../assets/images/center-pic.png';

const section2 = (props) => {
  const list = [
    {title: 'title1', description: 'description1'},
    {title: 'title2', description: 'description2'},
    {title: 'title3', description: 'description3'},
    {title: 'title4', description: 'description4'},
    {title: 'title5', description: 'description5'},
  ];

  return (
    <section className="section-2">
      <h3>About Us</h3>
      <p className="subtitle">Our company was founded in 2014 and has extensive experience in providing agency services for various commodities and vessel types in all Ukrainian ports.</p>
      <p className="description">Due to our best ralationship with port, state authorities and stevendoring companies, we guarantee:</p>
      <p className="img">
        <img src={imgImg} alt="" />
      </p>
    </section>
  );
};

export default section2;