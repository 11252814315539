import React from 'react';

import './Section.scss';
import logo from '../../assets/images/logo.png'

const section1 = () => {
  return (
    <section className="section-1">
      <img src={logo} alt="logo" />
    </section>
  );
};

export default section1;