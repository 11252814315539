import React from 'react';
import './App.css';

import Header from './components/Header/Header';
import Section1 from './components/Section-1/Section';
import Section2 from './components/Section-2/Section';
import Section3 from './components/Section-3/Section';
import Footer from './components/Footer/Footer';

const app = () => {
  return (
    <div className="App">
      <Header />
      <Section1 />
      <Section2 />      
      <Section3 />
      <Footer />
    </div>
  );
}

export default app;
